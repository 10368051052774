/* --------------------------------------------------------------
    Import CSS Dependencies
-------------------------------------------------------------- */
@import 'colors';
@import 'icons';
@import 'fonts';
@import 'default';
@import 'nav';
@import 'buttons';
/* --------------------------------------------------------------
    Start Project CSS
-------------------------------------------------------------- */
html {
  position: relative;
  height: 100%;
  background-color: #f8f9fa;
  // overflow-y: scroll;
  // -webkit-font-smoothing: antialiased;
  // -moz-osx-font-smoothing: grayscale;
}
body {
  height: 100%;
  font-family: var(--font-family);
  text-rendering: optimizeLegibility;
  color: #555555;
}
.wrap {
  padding: 30px 0;
}
.container-fluid.but-fixed {
  max-width: 1230px;
}
.footer {
  padding-top: 20px;
  padding-bottom: 15px;
  width: 100%;
  background-color: #f5f5f5;
  color: #555;
  border-top: 1px solid #e4e4e4;
  font-size: 0.9rem;
  p {
    margin: 0 0 5px 0;
  }
  p.trustmark {
    display: none;
    margin: 0;
  }
  a {
    color: #555;
  }
  .social a {
    border: 0;
  }
  img {
    display: inline-block;
  }
  #trustwaveSealImage {
    margin: 0 10px 30px 0;
  }
}
/* --------- asc styles --------- */
.skip-link {
  color: #fff !important;
  background: $theme-secondary !important;
  padding: 10px 20px !important;
  border-bottom: none !important;
  text-decoration: underline !important;
}
#header-wrap {
  background-color: #ebebed;
}
#header {
  a.logo {
    border: 0;
    width: 80%;
    max-width: 650px;
    img {
      margin-right: 5px;
      width: auto;
      height: auto;
      max-width: 40px;
    }
    span.big,
    span.small {
      font-size: 1.5em;
      color: #e41330;
      line-height: 1em;
    }
    span.big {
      font-weight: 600;
      font-family: var(--font-family);
    }
    span.small {
      font-size: 16px;
      font-family: var(--font-family);
    }
  }
  #support {
    display: none;
    float: right;
    margin: 0 0 0 0;
    width: auto;
    a.login {
      float: right;
      margin: 0 0 0 0;
      padding: 0 12px 2px 12px;
      font-weight: normal;
      color: #fff;
      background: $theme-primary;
      text-decoration: none;
      border-radius: 0 0 4px 4px;
      border: 0;
      &:before {
        display: inline-block;
        margin-right: 5px;
        width: inherit;
        height: inherit;
        content: '\e828';
        color: inherit;
        font-family: 'icons';
        font-weight: normal;
        text-align: center;
        font-size: 105%;
        vertical-align: top;
      }
      &:hover {
        background: lighten($theme-primary, 7.5%);
      }
    }
    a.help-center {
      // float: right;
      margin: 0 10px 0 0;
      font-weight: normal;
      color: #555;
      border: 0;
      text-decoration: none;
      &:before {
        display: inline-block;
        margin-right: 5px;
        width: inherit;
        height: inherit;
        content: '\e830';
        color: inherit;
        font-family: 'icons';
        font-weight: normal;
        text-align: center;
        font-size: 105%;
        vertical-align: top;
      }
      &:hover {
        text-decoration: underline;
        text-decoration-thickness: 1px;
      }
    }
  }
}
#banner-wrap {
  background: #fff url(../images/banner_bg.jpg) no-repeat 50% 50% / cover;
  #banner {
    h1 {
      color: $theme-primary;
    }
    #pitch {
      background: rgba(255, 255, 255, 0.8);
      border: 1px solid #86bbe5;
      max-width: 600px;
      .text-red {
        color: #c00;
      }
      .banner-highlight {
        background: #4b7297;
      }
    }
  }
}
#action-wrap {
  border-top: 1px solid $theme-primary;
  border-bottom: 1px solid $theme-primary;
  background-color: #eef3f8;
}
.action {
  border: 1px solid $theme-primary;
  h3 {
    color: $theme-primary;
  }
}
.callout {
  background: #f4eeee;
  border: 1px solid #e00;
}

/* ----------------------------------------------------------------------------
    Page Media Queries
----------------------------------------------------------------------------- */
// Small devices (landscape phones, 576px and up)
@media (min-width: 576px) {
}

// Medium devices (tablets, 768px and up)
@media (min-width: 768px) {
  .container-fluid.but-fixed {
    padding-left: 30px;
    padding-right: 30px;
  }
  #header a.logo {
    img {
      max-width: 55px;
      margin-right: 15px;
    }
    span.big {
      font-size: 1.9em;
    }
  }
}
// roughtly 900px/16px
@media only screen and (min-width: 56.25em) {
  #footer p.trustmark {
    display: block;
  }
  #header-wrap {
    border-top: 3px solid $theme-primary;
    #header #support {
      display: block;
    }
  }
}

// Large devices (desktops, 992px and up)
@media (min-width: 992px) {
  #header a.logo {
    width: 50%;
  }
  #header #support {
    display: block;
  }
}

// Extra large devices (large desktops, 1200px and up)
@media (min-width: 1200px) {
  #banner-wrap {
    #banner {
      background: url(../images/guy.png) no-repeat 30px 105%;
      background-size: 265px;
    }
  }
}
